import {
  handleError,
  BASE_URL,
  MAX_TIMEOUT_WAIT,
  mapApiV3toV2,
  getToken,
} from "../api/api.utilities";
import axios from "axios";

export async function getInitialData(token = getToken()) {
  try {
    const response = await axios({
      method: "get",
      timeout: MAX_TIMEOUT_WAIT,
      url: `${BASE_URL}/projects/brands-and-years`,
      headers: {
        Authorization: token,
        "content-type": "application/json",
      },
    });

    return response;
  } catch (error) {
    handleError(error);
  }
}

export async function getProjects(token) {
  try {
    const modelsData = await getProjectModels(token);
    const initialData = await getInitialData(token);
    const response = mapApiV3toV2.getModels(modelsData, initialData.data.data);

    response.data.result.forEach((element) => {
      element.brands.forEach((brand) => {
        brand.models.sort((a, b) => {
          if (a.model < b.model) return -1;
          if (a.model > b.model) return 1;
          return 0;
        });

        brand.models.forEach((model) => {
          model.submodels.sort((a, b) => {
            if (a.submodel < b.submodel) return -1;
            if (a.submodel > b.submodel) return 1;
            return 0;
          });
        });
      });
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function getVariants(token, projectId) {
  try {
    const headerValue = token;
    const response = await axios({
      method: "get",
      timeout: MAX_TIMEOUT_WAIT,
      url: BASE_URL + "/json-mappings/active/" + projectId,
      headers: {
        Authorization: headerValue,
        "content-type": "application/json",
      },
    });

    const v2data = mapApiV3toV2.getProjectJson(response.data);
    return v2data.data;
  } catch (error) {
    handleError(error);
  }
}

export async function getProjectModels(token) {
  try {
    const headerValue = token;
    const response = await axios({
      method: "get",
      timeout: MAX_TIMEOUT_WAIT,
      url: BASE_URL + "/projects/models",
      headers: {
        Authorization: headerValue,
        "content-type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function getSwatchImg(swatchName) {
  try {
    const token = getToken();
    const response = await axios({
      method: "get",
      timeout: MAX_TIMEOUT_WAIT,
      url: `${BASE_URL}/swatches/file-name/${swatchName.toUpperCase()}.png?token=${token}`,
    });
    return response;
  } catch (error) {
    handleError(error);
  }
}

export function getBrandLogoUrl(brandId, token = getToken()) {
  return `${BASE_URL}/car-brands/logo/${brandId}?token=${token}`;
}

export function getSwatchUrl(fileName, token = getToken()) {
  return `${BASE_URL}/swatches/file-name/${fileName}?token=${token}`;
}
