import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { SiteProvider } from "./contexts/SiteContext";
import ProtectedRoute from "./common/ProtectedRoute";
import LoadingBar from "./common/LoadingBar";
import styled from "styled-components";
import LoginPage from "./login/LoginPage";
import InactiveModal from "./common/InactiveModal";
import SelectionPage from "./selection/SelectionPage";
import ImagePage from "./image/ImagePage";
import PageNotFound from "./common/PageNotFound";
import RegisterPage from "./register/RegisterPage";
import SetPassword from "./login/SetPassword";
import HandleRequest from "./login/HandleRequest";
import DebugPage from "./debug/DebugPage";
import { RegisterProvider } from "./contexts/RegisterContext";
import "./App.css";

const queryClient = new QueryClient();
const basename = process.env.BASENAME;

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <InactiveModal />
      <SiteProvider>
        <RegisterProvider>
          <Container>
            <LoadingBar />
            <Routes basename={basename}>
              <Route path="/ui" element={<Navigate to="/" replace />} />

              <Route exact path="/" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/set-password/:encodedUserId/:token" element={<SetPassword />} />
              <Route path="/handle-request/:token" element={<HandleRequest />} />
              <Route path="/debug" element={<DebugPage />} />
              <Route
                path="/selection"
                exact
                element={<ProtectedRoute path="/selection" element={SelectionPage} />}
              />
              <Route
                path="/image"
                exact
                element={<ProtectedRoute path="/image" element={ImagePage} />}
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Container>
        </RegisterProvider>
      </SiteProvider>
    </QueryClientProvider>
  );
}

const Container = styled.div`
  height: 100vh;
  overflow: hidden;
`;
