import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Link from "../register/Link";
import Title from "../common/Title";
import { Base64 } from "js-base64";
import { handleRequest } from "../../services/user.service";

const PAGES = {
  HOME: "home",
  APPROVED: "approve",
  REJECTED: "reject",
  ERROR: "error",
};

const headerImgPath = {
  [PAGES.APPROVED]: {
    agency: "./assets/email/Mail_AgencyHeader_ApprovedPic.png",
    employee: "./assets/email/Mail_GMEmployeeHeader_ApprovedPic.png",
  },
  [PAGES.REJECTED]: {
    agency: "./assets/email/Mail_AgencyHeader_DeclinedPic.png",
    employee: "./assets/email/Mail_GMEmployeeHeader_DeclinedPic.png",
  },
};

function decodeStr(str) {
  if (str === null) return null;
  try {
    return Base64.decode(str);
  } catch {
    return null;
  }
}

function getHeaderImgPath(status, path) {
  return headerImgPath[status === "approve" ? PAGES.APPROVED : PAGES.REJECTED][
    path.includes("/gm-request") ? "employee" : "agency"
  ];
}

function getUrlParam(searchString, parameter, isEncoded = false) {
  const search = new URLSearchParams(searchString);
  return isEncoded ? decodeStr(search.get(parameter)) : search.get(parameter);
}

function CloseWindowLink() {
  return (
    <span style={{ display: "block", marginTop: "2rem" }}>
      You can now close this window manually or click{" "}
      <Link onClick={() => window.close()}>here</Link> to close.
    </span>
  );
}

function DisplayMessage({ userData }) {
  return (
    <DisplayMessageContainer>
      <BlockTitle color="#fff" style={{ display: "block", textTransform: "inherit" }}>
        You have successfully &quot;<u>{userData.status}</u>&quot; PicGen user registration for the
        following user:
      </BlockTitle>

      <br />

      <BlockTitle style={{ textTransform: "inherit" }}>Name: {userData.name}</BlockTitle>
      <BlockTitle style={{ textTransform: "inherit" }}>Email: {userData.email}</BlockTitle>

      <CloseWindowLink />
    </DisplayMessageContainer>
  );
}

export default function HandleRequest() {
  const { token } = useParams();

  const [page, setPage] = useState(PAGES.HOME);
  const [headerImg, setHeaderImg] = useState(null);

  const userData = useRef(null);

  const [approvePath, rejectPath] = [
    getUrlParam(window.location.search, "approve"),
    getUrlParam(window.location.search, "reject"),
  ];

  const submitRequest = async (action) => {
    const imgPath = getHeaderImgPath(action, approvePath || rejectPath);
    setHeaderImg(imgPath);

    try {
      const res = await handleRequest(action === "approve" ? approvePath : rejectPath, token, {});

      if (res.status === 200) {
        userData.current = res.data.data;
        action === "approve" ? setPage(PAGES.APPROVED) : setPage(PAGES.REJECTED);
      } else {
        console.error(res.data.message);
        setPage(PAGES.ERROR);
      }
    } catch (e) {
      console.error("Something went wrong. Please try again later.");
      console.error(e);
      setPage(PAGES.ERROR);
    }
  };

  useEffect(() => {
    if (approvePath) {
      submitRequest("approve");
    } else if (rejectPath) {
      submitRequest("reject");
    } else {
      setPage(PAGES.ERROR);
    }
  }, []);

  return (
    <Container>
      {page === PAGES.HOME && (
        <Box>
          <Title color="#fff">Please wait while we process your request...</Title>
        </Box>
      )}

      {page === PAGES.ERROR && (
        <>
          <Box>
            <div style={{ textAlign: "center" }}>
              <PrimaryText>An error occurred!</PrimaryText>
              <Title color="#fff">Please try again after some time.</Title>

              <CloseWindowLink />
            </div>
          </Box>
        </>
      )}

      {(page === PAGES.APPROVED || page === PAGES.REJECTED) && (
        <>
          <HeaderImg src={headerImg} alt="header" />

          <DisplayMessage userData={userData.current} />
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background: #1e2022;
  flex-direction: column;
`;

const PrimaryText = styled.h1`
  font-family: "Overpass-Medium";
  text-transform: uppercase;
  margin: 0;
`;

const Box = styled.div`
  display: flex;
  margin: 5em 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const DisplayMessageContainer = styled.div`
  text-align: center;
  margin-top: 4rem;
`;

const HeaderImg = styled.img`
  object-fit: contain;
  width: 100%;
`;

const BlockTitle = styled(Title)`
  display: block;
`;

DisplayMessage.propTypes = {
  userData: PropTypes.shape({
    status: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
  }),
};
