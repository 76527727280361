import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import Cookies from "js-cookie";

const ProtectedRoute = ({ element: Comp, ...rest }) => {
  function isLoggedIn() {
    return Cookies.get(`picgen`);
  }

  function getComponent() {
    return isLoggedIn() ? (
      <Comp {...rest} />
    ) : (
      <Navigate
        to={{
          pathname: "../",
        }}
      />
    );
  }

  return getComponent();
};

ProtectedRoute.propTypes = {
  path: PropTypes.string,
  element: PropTypes.func.isRequired,
};

export default ProtectedRoute;
