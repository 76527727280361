import { handleError, BASE_URL, MAX_TIMEOUT_WAIT, mapApiV3toV2 } from "../api/api.utilities";
import axios from "axios";

export const register = async (data) => {
  try {
    const response = await axios({
      method: "post",
      timeout: MAX_TIMEOUT_WAIT,
      url: BASE_URL + "/users",
      headers: { "content-type": "application/json" },
      data: JSON.stringify(data),
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const authenticate = async (user) => {
  try {
    const response = await axios({
      method: "post",
      timeout: MAX_TIMEOUT_WAIT,
      url: BASE_URL + "/users/authenticate",
      headers: { "content-type": "application/json" },
      data: JSON.stringify(user),
    });

    // HOTFIX: Restoring old obj structure, need to refactor SiteContext structure in the future
    const v3data = mapApiV3toV2.authenticate(response.data);
    return v3data;
  } catch (error) {
    handleError(error);
  }
};

export const resetPassword = async (encodedUserId, token, data) => {
  try {
    const userId = encodedUserId;
    const resetToken = token;
    const response = await axios({
      method: "post",
      timeout: MAX_TIMEOUT_WAIT,
      url: BASE_URL + "/users/reset-password/" + userId + "/" + resetToken,
      headers: { "content-type": "application/json" },
      data: JSON.stringify(data),
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const requestResetPassword = async (username) => {
  try {
    const data = { username };
    const response = await axios({
      data,
      method: "post",
      timeout: MAX_TIMEOUT_WAIT,
      url: BASE_URL + "/users/forgot-password",
      headers: { "content-type": "application/json" },
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const handleRequest = async (path, token, data) => {
  try {
    const response = await axios({
      data: { ...data, token },
      method: "post",
      timeout: MAX_TIMEOUT_WAIT * 3,
      url: BASE_URL + path,
      headers: { "content-type": "application/json" },
    });
    return response;
  } catch (e) {
    return e?.response || { status: 500, data: { message: "Failed to process your request!" } };
  }
};
