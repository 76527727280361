import React from "react";
import styled from "styled-components";
import Art from "../common/Art";
import Sidebar from "../common/Sidebar";
import Divider from "../common/Divider";
import LoginForm from "./LoginForm";
import CreateAccount from "./CreateAccount";
import Title from "../common/Title";

export default function LoginPage() {
  const handleRegistrationGuideDownload = () => {
    const link = document.createElement("a");
    link.href = "./assets/3DEXCITE Picture Generator Registration Guide v7.pdf";
    link.download = "3DEXCITE Picture Generator Registration Guide v7.pdf";
    link.dispatchEvent(new MouseEvent("click"));
  };

  return (
    <Container>
      <Art />
      <Sidebar>
        <Section>
          <Title>Sign In</Title>
          <Divider />
          <LoginForm />
        </Section>
        <Section>
          <Title>Picgen Help</Title>
          <Divider />
          <CreateAccount />

          <GuideContainer onClick={handleRegistrationGuideDownload}>
            <GuideLink>Download User Registration Guide</GuideLink>
            <Icon src={"./assets/icons/pdf.svg"} />
          </GuideContainer>
        </Section>
      </Sidebar>
    </Container>
  );
}

const Container = styled.main`
  height: 100%;
  display: grid;
  grid-template-columns: auto 400px;
  grid-template-areas: "art sidebar";
  overflow: auto;
`;

const Section = styled.section`
  margin-bottom: 15%;
`;

const GuideContainer = styled.div`
  cursor: pointer;
  display: flex;
  margin-top: 2rem;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

const GuideLink = styled.a`
  color: white;
  font-size: 0.8em;
`;

const Icon = styled.img`
  width: 1.2em;
  filter: brightness(0) invert(1);
`;
